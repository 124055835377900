
import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  },
  {
  path:'/services',
  name:'services',
  component: () => import('../views/ServicesView.vue')
  },
  {
    path:'/galery',
    name:'galery',
    component: () => import('../views/GaleryView.vue')
  },
  {
    path:'/contact',
    name:'contact',
    component: () => import('../views/ContactView.vue')
  },
{
  path:'/real',
  name:'real',
  component: () => import('../views/RealView.vue')
},
{
  path:'/aesthetics',
  name:'aesthetics',
  component: () => import('../views/AestheticsView.vue')
},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
