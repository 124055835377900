<template>
<p id="language">Language</p>
&nbsp;&nbsp;<p id="text" v-html="$t('home.text')"></p>
&nbsp;&nbsp;<p id="text1" v-html="$t('home.textelefon')"></p>
  
    <img id="logo1" src="dose.png" />
    <img id="logo11" src="image.png" />

 <img id="img" src="ture.png" />
 <img id="img1" src="signal1white.png" />
 <img id="idem" src="yea.jpg" />
 <img id="logo" src="dose.png" />
 <img id="logo2" src="image.png" />
 <img id="iphone" src="imag.jpg" /> 
 <img id="signa" src="celeb.png" />
  
 <div class="ice">
<p  id="rel"> Home </p>
<p  id="rel"> Skin affections </p>
<p  id="rel"> Surgery </p>
<p  id="rel"> Aesthetics </p>
<p  id="rel"> Contact & Schedule </p>
</div> 
<div class="loader">
      <div style="--i: 1"></div>
      <div style="--i: 2"></div>
      <div style="--i: 3"></div>
      <div style="--i: 4"></div>
    </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
  }
}
</script>
<style scoped>
#language {
  position:absolute;
  right:0;
  top:0;
  margin-right:5.3em;
  margin-top:2.1em;
  font-size:17px;
  font-weight:550;
  z-index:99;
  color:black;
  font-family: 'Cormorant Garamond', serif;
}
.loader::before {
  content: "";
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(8px);
  position: absolute;
  width: 140px;
  height: 55px;
  z-index: 20;
  left:40em;
  top:10em;
  display:none;
  border-radius: 0 0 10px 10px;
  border: 1px solid rgba(255, 255, 255, 0.274);
  border-top: none;
  box-shadow: 0 15px 20px rgba(0, 0, 0, 0.082);
  animation: anim2 2s infinite;
}
.loader {
  position:absolute;
  left:47em;
  top:10em;
  transform:rotate(90deg);
  z-index:-1;
}

.loader div {
  background: rgb(228, 228, 228);
  border-radius: 50%;
  width: 25px;
  height: 25px;
  z-index: -1;
  animation: anim 2s infinite linear;
  animation-delay: calc(-0.3s * var(--i));
  transform: translateY(5px);
  margin: 0.2em;
  
}

@keyframes anim {
  0%,
  100% {
    transform: translateY(5px);
  }
  50% {
    transform: translateY(-65px);
  }
}

@keyframes anim2 {
  0%,
  100% {
    transform: rotate(-10deg);
  }
  50% {
    transform: rotate(10deg);
  }
}

#img {
  position:absolute;
  width:24%;
  z-index:99;
  left:0;
  top:0;
  margin-left:7.5em;
  margin-top:21em;
}
#img1 {
  position:absolute;
  width:24%;
  z-index:99;
  left:0;
  top:0;
  margin-left:61em;
  margin-top:20em;
  display:none;
}
.ice {
  display:grid;
  grid-gap:2.1px;
  position:fixed;
  font-size:20px;
  left:3.2em;
  top:3.6em;
  color:white;
  font-weight:lighter;
  z-index:99;
}
#rel {
  position:relative;
  top:1.2em;
  font-family: 'Cormorant Garamond', serif;
  color:black;
}
#signa {
  position:absolute;
  width:10%;
  z-index:99;
  left:0;
  top:0;
  margin-top:3em;
  margin-left:23em;
  
}
#iphone {
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  filter:grayscale(0%);
  display:none;
}
#logo {
  position:absolute;
  left:0;
  top:0;
  margin-left:0;
  margin-top:-0.2em;
  width:500px;
  height:80px;
  z-index:99;
}
#logo2 {
  position:absolute;
  left:0;
  top:0;
  margin-left:0;
  margin-top:-0.2em;
  width:500px;
  height:80px;
  z-index:99;
  display:none;
}
#logo:hover {
  opacity:0.3;
  cursor:pointer;
}

#logo1 {
position:absolute;
 width:400px;
 margin-left:34em;
 z-index:99;
 left:0;
bottom:0;
 
}
#logo11 {
position:absolute;
 width:400px;
 margin-left:34em;
 z-index:99;
 left:0;
bottom:0;
 display:none;
}

#text {
  line-height:32px;
  font-size:26px;
  height:10em;
  left:0;
  top:0;
  margin-left:1em;
  margin-top:15em;
  width:20em;
  height:7em;
  position:absolute;
  color:black;
  padding-left:1em;
  padding-right:0.5em;
  padding-top:0.5em;
  opacity:0.8;
  border-radius:5px;
  z-index:99;
  font-weight:500;
  font-family: 'Cormorant Garamond', serif;
}

#text1 {
  line-height:32px;
  font-size:26px;
  height:10em;
  left:0;
  top:0;
  margin-left:1em;
  margin-top:15em;
  width:20em;
  height:7em;
  position:absolute;
  color:black;
  padding-left:1em;
  padding-right:0.5em;
  padding-top:0.5em;
  opacity:0.8;
  border-radius:5px;
  z-index:99;
  font-weight:500;
  font-family: 'Cormorant Garamond', serif;
  display:none;
}
#titlu {
  font-size:14px;
  color:white;
}

#idem {
  width:100%;
  height:100%;
  position:absolute;
  top:0;
  left:0;
  filter:grayscale(0%);
  
}
@media (min-width:1400px) and (max-width:1499px) {
  #text {
    margin-left:41em;
    margin-top:19em;
    font-size:22px;
  }
  #signa {
    margin-top:2.3em;
  }
  #img {
    margin-left:61em;
    margin-top:20em;
  }
  #logo {
    margin-top:-0.66em;
  }
  #language {
 margin-top:1.5em;
}
  
}
@media (min-width:1300px) and (max-width:1399px) {
  #img {
    left:0;
    top:0;
    margin-left:58em;
  }
  #signa {
    margin-left:22em;
    margin-top:2.6em;
  }
  #text {
    font-size:19px;
    margin-left:42em;
    margin-top:20.5em;
  }
  #language {
 margin-top:1.5em;
 margin-right:4.7em;
}
  #logo1 {
    margin-left:29em;

  }
  #logo {
    margin-top:-0.7em;
    width:460px;
  }

}
@media (min-width:1200px) and (max-width:1299px) {
  #img { 
    margin-left:49em;
    margin-top:14em;
    
  }
  #language {
 margin-top:1.7em;
 margin-right:4.7em;
 font-size:15px;
}
  #text {
    margin-left:42em;
    font-size:17px;
    margin-top:19em;
  }
  #signa {
    margin-left:19em;
    margin-top:2.5em;
  }
  #logo1 {
    margin-left:27em;
    margin-bottom:0em;
  }
  
  #logo {
    width:400px;
    margin-top:-0.7em;
    height:80px;
  }
}
@media (min-width:1100px) and (max-width:1199px) {
  #logo {
    width:370px;
    margin-top:-0.8em;
  }
  #logo1 {
    margin-left:23.5em;
  }
  #language {
 margin-top:1.7em;
 margin-right:5.6em;
 font-size:15px;
}
  #text {
    margin-left:37em;
    margin-top:17em;
    font-size:17px;
  }
  .loader {
  left:35.5em;
  top:5em;  
 
  
}
  #img {
    margin-left:46em;
    margin-top:13.3em;
  }
  #signa {
    margin-top:2.3em;
    margin-left:17em;
  }
  
}
@media (min-width:1000px) and (max-width:1099px) {
  #logo {
    width:350px;
    height:60px;
    margin-top:-.4em;
  }
  .loader {
  left:31.5em;
  top:5em; 
}
#language {
 margin-top:1.7em;
 margin-right:5.6em;
 font-size:13px;
}
  #text {
    margin-left:3em;
  margin-top:21em;
  line-height:17px;
  font-size:16px;
  }
  #img {
    margin-left:6em;
    margin-top:18.5em;
  }
  #signa {
    margin-top:1.9em;
    margin-left:16.5em;
  }
 
  #logo1 {
    width:300px;
    margin-left:23em;
  }
  .ice {
    top:4.2em;
  }
  

}
@media (min-width:900px) and (max-width:999px) {
  #logo {
    margin-top:-0.1em;
    width:320px;
    height:50px;
  }
  #language {
 margin-top:2em;
 margin-right:4.4em;
 font-size:11px;
}
  .ice {
    top:2.6em;
  }
  #signa {
    margin-top:1.8em;
    margin-left:15.5em;
  }
  .loader {
  left:26.5em;
  top:5em;  
 
  
}
  #logo1 {
    width:250px;
    height:40px;
    margin-left:20.5em;
    margin-bottom:0.1em;
  }
  #text {
    margin-left:1em;
  margin-top:19.5em;
  line-height:17px;
  font-size:16px;
  }
  #img {
    margin-left:5em;
    margin-top:17.5em;
  }
  

}
@media (min-width:800px) and (max-width:899px) {
  #logo {
    margin-top:0em;
    width:260px;
    height:40px;
  }
  #language {
 margin-top:1.5em;
 margin-right:4.6em;
 font-size:11px;
}
  #logo1 {
    width:250px;
    height:40px;
    margin-left:18.5em;
    margin-bottom:0.1em;
  }
  .ice {
    left:3em;
    top:4.4em;
  }
  #text {
    margin-left:0.5em;
  margin-top:24.5em;
  line-height:17px;
  font-size:14px;
  }
  #img {
    margin-left:4em;
    margin-top:19.5em;
  }
  #signa {
    margin-top:1.6em;
    margin-left:12.5em;
  }
  .loader {
  left:24.5em;
  top:5em;  
 
  
}

} 
@media (min-width:700px) and (max-width:799px) {
  #logo {
    margin-top:-0.1em;
    width:200px;
    height:40px;
  }
  #logo1 {
    width:200px;
    height:30px;
    margin-left:17em;
  }
  #language {
 margin-top:1.7em;
 margin-right:4.6em;
 font-size:10px;
}
#select {
  top:2em;
}
  #signa {
    margin-top:1.5em;
    margin-left:8.5em;
  }
  .ice {
    left:3.1em;
    top:4.5em;
  }
  #text {
    margin-left:1em;
  margin-top:24.5em;
  line-height:16px;
  font-size:14px;
  }
  #img {
    margin-left:3.5em;
    margin-top:18.7em;
    width:30%;
  }
  .loader {
  left:21.5em;
  top:5em;  
 
  
}
}
@media (min-width:600px) and (max-width:699px) {
  #logo {
    width:200px;
    margin-left:-0.1em;
    margin-top:-0.2em;
    height:40px;
  }
  #language {
 margin-top:1.3em;
 margin-right:4.6em;
 font-size:10px;
}
  .ice {
    left:3.1em;
    top:2em;
  }
  #logo1 {
    width:170px;
    height:30px;
    margin-left:13.8em;
  }
  #signa {
    margin-top:1.2em;
    margin-left:9em;
  }
  #text {
    margin-left:4em;
  margin-top:19.5em;
  line-height:13px;
  font-size:12px;
  }
  #img {
    margin-left:6em;
    margin-top:12.5em;
    width:27%;
  }
  .loader {
  left:18em;
  top:5em;  
 
  
}
  
}

@media (min-width:300px) and (max-width:349px) {
#logo {
  width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10px;
  margin-top:3.2em;
  left:22.2em;
}
#logo2 {
display:block;
width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
}
.loader {
  display:none;
}
#logo1 {
    width:180px;
    margin-left:4em;
    display:none;
  }
  #logo11 {
    width:180px;
    left:-30em;
    display:block;
  }
#text {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12em;
    margin-left:25em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:none;
  }
  #text1 {
  font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12em;
    margin-left:25em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #signa {
    display:none;
  }
  #img {
    margin-left:12em;
    margin-top:5.4em;
    width:24%;
    display:none;
  }
  #img1 {
    margin-left:11.7em;
    margin-top:5.5em;
    width:30%;
    display:block;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  #rel {
    color:white;
    top:-3.9em;
    line-height:6.2px;
  }
  
}
@media (min-width:350px) and (max-width:399px) {
  #logo {
  width:350px;
  margin-top:-0.5em;
  height:60px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10.5px;
  margin-top:3.6em;
  left:25.7em;
}
.loader {
  display:none;
}
#logo2 {
  width:350px;
  margin-top:-0.5em;
  height:60px;
  right:0;
  display:block;
}
#logo1 {
    width:180px;
    margin-left:5.5em;
    display:none;
  }
  #logo11 {
    width:180px;
    margin-left:6em;
    display:block;
  }
#text {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:11em;
    margin-left:25em;
    background-color:#d0d0d0;
    color:#36738C;
    opacity:0.9;
    padding:7px;
    display:none;
  }
  #text1 {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12.3em;
    margin-left:25em;
    background-color:#d0d0d0;
    color:#36738C;
    opacity:0.9;
    padding:7px;
    display:block;
  }
  #rel {
    color:white;
    top:-3.8em;
  }
  #signa {
    display:none;
  }
  #img1 {
    margin-left:12em;
    margin-top:5.5em;
    width:24%;
    display:block;
  }
  #img {
    display:none;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
}
@media (min-width:400px) and (max-width:449px) {
  #logo {
  width:400px;
  margin-top:-0.5em;
  height:60px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10.5px;
  margin-top:3.6em;
  left:30.7em;
}
.loader {
  display:none;
}
#rel {
    color:white;
    top:-3.8em;
  }
#logo2 {
  width:400px;
  margin-top:-0.5em;
  height:60px;
  right:0;
  display:block;
}
#logo1 {
    width:180px;
    margin-left:6.8em;
    display:none;
  }
  #logo11 {
    width:180px;
    left:-27em;
    display:block;
  }
#text1 {
    font-size:10px;
    line-height:10px;
    width:14em;
    top:0;
    background-color:#d0d0d0;
    color:#36738C;
    left:0;
    height:10em;
    margin-left:16em;
    margin-top:12em;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #text {
    display:none;
  }
  #signa {
    display:none;
  }
  #img1 {
    margin-left:11em;
    margin-top:6.2em;
    width:30%;
    display:block;
  }
  #img {
    display:none;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  
}
@media (min-width:450px) and (max-width:499px) {
  #logo {
  width:450px;
  margin-top:-0.5em;
  height:70px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10.5px;
  margin-top:4em;
  left:35.5em;
}
.loader {
  display:none;
}
#logo2 {
  width:450px;
  margin-top:-0.5em;
  height:70px;
  right:0;
  display:block;
}
#rel {
    color:white;
    top:-3.8em;
  }
#logo1 {
    width:180px;
    margin-left:8.8em;
    display:none;
  }
#text1 {
    font-size:12px;
    line-height:11px;
    width:14em;
    top:0;
    left:0;
    height:9em;
    margin-left:14em;
    margin-top:10.3em;
    background-color:#d0d0d0;
    color:#36738C;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #text {
    display:none;
  }
  #signa {
    display:none;
  }
  #img1 {
    margin-left:12em;
    margin-top:6.4em;
    width:30%;
    display:block;
  }
  #img {
    display:none;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  #logo11 {
    width:180px;
    left:-25em;
    display:block;
  }
}
@media (min-height:300px) and (max-height:349px) and (min-width:300px) and (max-width:349px) {
#logo {
  width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10px;
  margin-top:3.2em;
  left:22.2em;
}
#logo2 {
display:block;
width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
}
.loader {
  display:none;
}
#logo1 {
    width:180px;
    margin-left:4em;
    display:none;
  }
  #logo11 {
    width:180px;
    left:-30em;
    display:block;
  }
#text {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12em;
    margin-left:25em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:none;
  }
  #text1 {
  font-size:9px;
    line-height:9px;
    width:15em;
    top:0;
    left:0;
    height:8em;
    margin-left:13em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #signa {
    display:none;
  }
  #img {
    margin-left:12em;
    margin-top:5.4em;
    width:24%;
    display:none;
  }
  #img1 {
    margin-left:8.3em;
    margin-top:7em;
    width:40%;
    display:block;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  #rel {
    color:white;
    top:-4.7em;
    left:-3.8em;
    line-height:9.5px;
  }
  
}
@media (min-width:350px) and (max-width:399px) and (min-height:300px) and (max-height:349px) {
#logo {
  width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10px;
  margin-top:3.6em;
  left:27.4em;
}
#logo2 {
display:block;
width:350px;
  margin-top:-0.5em;
  height:50px;
  right:0;
}
.loader {
  display:none;
}
#logo1 {
    width:180px;
    margin-left:4em;
    display:none;
  }
  #logo11 {
    width:180px;
    left:0em;
    display:block;
  }
#text {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12em;
    margin-left:25em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:none;
  }
  #text1 {
  font-size:10px;
    line-height:9px;
    width:15em;
    top:0;
    left:0;
    margin-top:13em;
    height:7.5em;
    margin-left:12em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #signa {
    display:none;
  }
  #img {
    margin-left:12em;
    margin-top:5.4em;
    width:24%;
    display:none;
  }
  #img1 {
    margin-left:8em;
    margin-top:6.5em;
    width:42%;
    display:block;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  #rel {
    color:white;
    top:-4.7em;
    left:-4em;
    line-height:9.4px;
  }
  
}
@media (min-width:400px) and (max-width:449px) and (min-height:300px) and (max-height:349px) {
#logo {
  width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:10px;
  margin-top:4em;
  left:32.4em;
}
#logo2 {
display:block;
width:400px;
  margin-top:-0.5em;
  height:60px;
  right:0;
}
.loader {
  display:none;
}
#logo1 {
    width:180px;
    margin-left:4em;
    display:none;
  }
  #logo11 {
    width:180px;
    left:-27em;
    display:block;
  }
#text {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12em;
    margin-left:25em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:none;
  }
  #text1 {
  font-size:12px;
    line-height:12px;
    width:15em;
    top:0;
    left:0;
    height:8em;
    margin-top:10em;
    margin-left:9.5em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #signa {
    display:none;
  }
  #img {
    margin-left:12em;
    margin-top:5.4em;
    width:24%;
    display:none;
  }
  #img1 {
    margin-left:8em;
    margin-top:5.5em;
    width:42%;
    display:block;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  #rel {
    color:white;
    top:-3.7em;
    left:-3.8em;
    line-height:9.4px;
  }
  
}
@media (min-width:450px) and (max-width:499px) and (min-height:300px) and (max-height:349px) {
#logo {
  width:300px;
  margin-top:-0.5em;
  height:50px;
  right:0;
  display:none;
}
#language {
  display:block;
  color:white;
  font-size:11px;
  margin-top:3.8em;
  left:33.4em;
}
#logo2 {
display:block;
width:450px;
  margin-top:-0.7em;
  height:70px;
  right:0;
}
.loader {
  display:none;
}
#logo1 {
    width:180px;
    margin-left:4em;
    display:none;
  }
  #logo11 {
    width:180px;
    left:-25.5em;
    display:block;
  }
#text {
    font-size:7px;
    line-height:9px;
    width:14em;
    top:0;
    left:0;
    height:12em;
    margin-left:25em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:none;
  }
  #text1 {
  font-size:12px;
    line-height:12px;
    width:15em;
    top:0;
    left:0;
    height:8.5em;
    margin-top:10em;
    margin-left:11em;
    color:#36738C;
    background-color:#d0d0d0;
    opacity:0.9;
    padding:7px;
    border-radius:5px;
    display:block;
  }
  #signa {
    display:none;
  }
  #img {
    margin-left:12em;
    margin-top:5.4em;
    width:24%;
    display:none;
  }
  #img1 {
    margin-left:8.5em;
    margin-top:5.5em;
    width:42%;
    display:block;
  }
  #menu {
   margin-left:-30em;
  }
  #idem {
    display:none;
  }
  #iphone {
    display:block;
  }
  #rel {
    color:white;
    top:-3.7em;
    left:-3.8em;
    line-height:9.4px;
  }
  
}
@media (min-width:300px) and (max-width:349px) and (min-height:350px) and (max-height:399px) {
  #text1 {
    font-size:10px;
    height:8em;
    width:14em;
    margin-left:12em;
  }
  #img1 {
    width:42%;
    margin-left:8.3em;
    margin-top:8em;
  }
  #rel {
    line-height:9px;
    top:-4.5em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:350px) and (max-height:399px) {
  #text1 {
    font-size:11px;
    height:7.5em;
    width:14em;
    margin-left:12em;
    margin-top:13em;
  }
  #img1 {
    width:42%;
    margin-left:8.9em;
    margin-top:7.3em;
  }
  #rel {
    line-height:9px;
    top:-4.5em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:350px) and (max-height:399px) {
  #text1 {
    font-size:12px;
    height:7.5em;
    width:14em;
    margin-left:12em;
    margin-top:13em;
  }
  #img1 {
    width:42%;
    margin-left:9.5em;
    margin-top:7.9em;
  }
  #rel {
    line-height:9.3px;
    top:-3.6em;
  }
}
@media (min-width:450px) and (max-width:499px) and (min-height:350px) and (max-height:399px) {
  #text1 {
    font-size:13px;
    height:7.5em;
    width:14em;
    margin-left:11em;
    margin-top:10em;
  }
  #img1 {
    width:40%;
    margin-left:9.5em;
    margin-top:6.2em;
  }
  #rel {
    line-height:9.3px;
    top:-3.6em;
  }
}
@media (min-width:300px) and (max-width:349px) and (min-height:400px) and (max-height:449px) {
#text1 {
  font-size:11px;
  margin-left:9.9em;
  height:7.4em;
  margin-top:14em;
  background-color:none;

}
#rel {
  line-height:9px;
  top:-4.5em;
}
#img1 {
  width:48%;
  margin-top:8.2em;
  margin-left:7.5em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:400px) and (max-height:449px) {
#text1 {
  font-size:12px;
  margin-left:11em;
  height:7.1em;
  margin-top:14em;
}
#rel {
  line-height:9px;
  top:-4.5em;
}
#img1 {
  width:44%;
  margin-top:8.8em;
  margin-left:9.1em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:400px) and (max-width:449px) and (min-height:400px) and (max-height:449px) {
  #text1 {
  font-size:13px;
  height:8.3em;
  margin-left:10em;
  line-height:12px;
  padding:10px;
  margin-top:13em;
}
#rel {
  line-height:9px;
  top:-3.5em;
}
#img1 {
  width:43%;
  margin-top:8.5em;
  margin-left:9.2em;
display:block;
}
#img {
  display:none;
}
#logo11 {
  left:-27em;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:400px) and (max-height:449px) {
  #text1 {
  font-size:14px;
  height:8em;
  margin-left:9em;
  margin-top:12em;
  line-height:12px;
  padding:10px;
}
#rel {
  line-height:9.3px;
  top:-3.6em;
}
#logo11 {
  display:block;
  width:180px;
  left:-25em;
}
#img1 {
  width:40%;
  margin-top:8.5em;
  margin-left:9.1em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:450px) and (max-height:499px) {
#text1 {
  font-size:13px;
  margin-left:4.5em;
  height:9em;
  line-height:13px;
  margin-top:17em;
  background-color:none;

}
#rel {
  line-height:9px;
  top:-4.5em;
}
#img1 {
  width:57%;
  margin-top:11.8em;
  margin-left:4.5em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:450px) and (max-height:499px) {
#text1 {
  font-size:14px;
  margin-left:6em;
  height:7.7em;
  line-height:12px;
  margin-top:15em;
}
#rel {
  line-height:9px;
  top:-4.5em;
}
#logo11 {
  margin-left:5.5em;
}
#img1 {
  width:52%;
  margin-top:11.2em;
  margin-left:6.1em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:400px) and (max-width:449px) and (min-height:450px) and (max-height:499px) {
  #text1 {
  font-size:13px;
  height:8.3em;
  margin-left:8.4em;
  line-height:12px;
  padding:10px;
  margin-top:15em;
}
#rel {
  line-height:9px;
  top:-3.5em;
}
#img1 {
  width:43%;
  margin-top:10.2em;
  margin-left:7.9em;
display:block;
}
#img {
  display:none;
}
#logo11 {
  left:-27em;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:450px) and (max-height:499px) {
  #text1 {
  font-size:14px;
  height:8em;
  margin-left:9em;
  margin-top:12em;
  line-height:12.5px;
  padding:10px;
}
#rel {
  line-height:9.3px;
  top:-3.6em;
}
#logo11 {
  display:block;
  width:180px;
  left:-25em;
}
#img1 {
  width:40%;
  margin-top:8.5em;
  margin-left:9.1em;
  display:block;
}
#img {
  display:none;
}
}

@media (min-width:300px) and (max-width:349px) and (min-height:500px) and (max-height:549px) {
  #text1 {
  font-size:13px;
  height:8.3em;
  margin-left:4em;
  margin-top:19em;
  line-height:12px;
  padding:10px;
}
#rel {
  line-height:9.3px;
  top:-4.6em;
}
#img {
  width:44%;
  margin-top:11em;
  margin-left:6.1em;
  display:none;
}
#language {
  margin-left:-0.2em;
}
#img1 {
  width:58%;
  margin-top:13.5em;
  margin-left:4em;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:500px) and (max-height:549px) {
  #text1 {
  font-size:14px;
  height:7.8em;
  margin-left:5em;
  margin-top:16.5em;
  line-height:12px;
  padding:10px;
}
#img1 {
  width:50%;
  margin-top:12.7em;
  margin-left:5.5em;
display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:5.5em;
}
#rel {
  top:-4.5em;
  line-height:9px;
}
}

@media (min-width:400px) and (max-width:449px) and (min-height:500px) and (max-height:549px) {
  #text1 {
  font-size:14px;
  height:9.2em;
  margin-left:7.1em;
  margin-top:16em;
  line-height:14px;
  padding:10px;
}
#img1 {
  width:40%;
  margin-top:12em;
  margin-left:8.1em;
display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:34.4em;
  display:block;
}
#rel {
  top:-3.4em;
  left:-3em;
}
#language {
  margin-left:-1em;
}

}
@media (min-width:450px) and (max-width:499px) and (min-height:500px) and (max-height:549px) {
  #text1 {
  font-size:15px;
  height:8.4em;
  margin-left:7.5em;
  margin-top:14.6em;
  line-height:14px;
  padding:10px;
}
#logo11 {
  margin-left:33.5em;
}
#img1 {
  width:40%;
  margin-top:11.7em;
  margin-left:8.4em;
display:block;
}
#language {
  margin-left:-1em;
}
#img {
  display:none;
}
#rel {
  top:-3.2em;
  left:-3em;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:550px) and (max-height:599px) {
  #text1 {
  font-size:13px;
  height:8.3em;
  margin-left:4em;
  margin-top:19em;
  line-height:12px;
  padding:10px;
}
#rel {
  line-height:9.3px;
  top:-4.6em;
}
#img {
  width:44%;
  margin-top:11em;
  margin-left:6.1em;
  display:none;
}
#language {
  margin-left:-0.2em;
}
#img1 {
  width:58%;
  margin-top:13.5em;
  margin-left:4em;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:550px) and (max-height:599px) {
  #text1 {
  font-size:14px;
  height:7.8em;
  margin-left:5em;
  margin-top:16.5em;
  line-height:12px;
  padding:10px;
}
#img1 {
  width:50%;
  margin-top:12.7em;
  margin-left:5.5em;
display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:5.5em;
}
#rel {
  top:-4.5em;
  line-height:9px;
}
}

@media (min-width:400px) and (max-width:449px) and (min-height:550px) and (max-height:599px) {
  #text1 {
  font-size:14px;
  height:9.2em;
  margin-left:7.1em;
  margin-top:16em;
  line-height:14px;
  padding:10px;
}
#img1 {
  width:40%;
  margin-top:12em;
  margin-left:8.1em;
display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:34.4em;
  display:block;
}
#rel {
  top:-3.2em;
  left:-3em;
}
#language {
  margin-left:-1em;
}

}
@media (min-width:450px) and (max-width:499px) and (min-height:550px) and (max-height:599px) {
  #text1 {
  font-size:15px;
  height:8.4em;
  margin-left:7.5em;
  margin-top:14.6em;
  line-height:14px;
  padding:10px;
}
#logo11 {
  margin-left:33.5em;
}
#img1 {
  width:40%;
  margin-top:11.7em;
  margin-left:8.4em;
display:block;
}
#language {
  margin-left:-1em;
}
#img {
  display:none;
}
#rel {
  top:-3.2em;
  left:-3em;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:600px) and (max-height:649px) {
#img1 {
  margin-top:13.5em;
  width:50%;
  margin-left:5em;
}
#text1 {
  margin-top:20em;
  font-size:12px;
  height:9.3em;
  line-height:12px;
  margin-left:5em;
}
#logo11 {
  margin-left:33.9em;
  display:block;
}
#rel {
  top:-3.6em;
  line-height:9.3px;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:600px) and (max-height:649px) {
#img1 {
  margin-top:13.5em;
  width:50%;
  margin-left:5.8em;
  display:block;
}
#img {
  display:none;
}
#text1 {
  margin-top:19em;
  margin-left:6em;
  line-height:14px;
  font-size:13px;
  height:9.8em;
}
#logo11 {
  margin-left:5.5em;
}
#rel {
  left:-2.6em;
  top:-3.1em;
  line-height:9.3px;
}

}
@media (min-width:400px) and (max-width:449px) and (min-height:600px) and (max-height:649px) {
#img {
  display:none;
}
#img1 {
  margin-top:11.7em;
  width:46%;
  margin-left:6.7em;
  display:block;
}
#rel {
  left:-3.8em;
  top:-3.1em;
  line-height:9.3px;
}
#text1 {
  margin-top:14.5em;
  left:-10em;
  height:9em;
  line-height:14px;
  font-size:15px;
}
#logo11 {
  margin-left:34em;
  display:block;
}
#language {
  left:30em;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:600px) and (max-height:649px) {
  #text1 {
    font-size:16px;
    line-height:15px;
    height:9em;
    margin-top:16.5em;
    margin-left:6.8em;
  }
  #rel {
  top:-2em;
  line-height:10.7px;
  left:-2em;
}
  #img {
    margin-left:9em;
    margin-top:14em;
    display:none;
  }
  #img1 {
    margin-left:7.8em;
    margin-top:14.6em;
    display:block;
    width:44%;
  }
  #logo11 {
    display:block;
    width: 180px;
margin-left:33.4em;
  }
  #language {
  left:35em;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:650px) and (max-height:699px) {
#img1 {
  margin-top:13.5em;
  width:50%;
  margin-left:5em;
}
#text1 {
  margin-top:20em;
  font-size:12px;
  height:9.2em;
  line-height:12px;
  margin-left:5em;
}
#logo11 {
  margin-left:33.9em;
  display:block;
}
#rel {
  top:-3.8em;
  line-height:9.7px;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:650px) and (max-height:699px) {
#img1 {
  margin-top:13.5em;
  width:50%;
  margin-left:5.8em;
  display:block;
}
#img {
  display:none;
}
#text1 {
  margin-top:19em;
  margin-left:6em;
  line-height:14px;
  font-size:13px;
  height:10em;
}
#logo11 {
  margin-left:5.5em;
}
#rel {
  left:-2.8em;
  line-height:9.4px;
  top:-3.1em;
}

}
@media (min-width:400px) and (max-width:449px) and (min-height:650px) and (max-height:699px) {
#img {
  display:none;
}
#img1 {
  margin-top:14.3em;
  width:46%;
  margin-left:6.7em;
  display:block;
}
#rel {
  left:-3.8em;
  top:-3.1em;
  line-height:9.3px;
}
#text1 {
  margin-top:16.5em;
  left:-11em;
  height:8.3em;
  line-height:15px;
  font-size:16px;
}
#logo11 {
  margin-left:34em;
  display:block;
}
#language {
  left:30em;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:650px) and (max-height:699px) {
  #text1 {
    font-size:17px;
    line-height:15px;
    height:8.1em;
    margin-top:15.5em;
    margin-left:6.3em;
  }
  #rel {
  top:-2.2em;
  left:-2em;
  line-height:11.7px;
}
  #img {
    margin-left:9em;
    margin-top:14em;
    display:none;
  }
  #img1 {
    margin-left:8.5em;
    margin-top:14.3em;
    display:block;
    width:44%;
  }
  #logo11 {
    display:block;
    width: 180px;
margin-left:33.4em;
  }
  #language {
  left:35em;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:700px) and (max-height:749px) {
  #text1 {
  font-size:15px;
  margin-left:3em;
  height:7.8em;
  margin-top:20em;
  line-height:12px;
}
#rel {
  top:-3.9em;
  line-height:12.5px;
}
#img1 {
  width:62%;
  margin-top:16.7em;
  margin-left:4em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:700px) and (max-height:749px) {
  #text1 {
  font-size:16px;
  margin-left:3.6em;
  margin-top:17.6em;
  height:7.8em;
  line-height:13px;

}
#rel {
  top:-3.2em;
  line-height:12.2px;
}
#img1 {
  width:60%;
  margin-top:15.3em;
  margin-left:4.5em;
  display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:5.3em;
}
}
@media (min-width:400px) and (max-width:449px) and (min-height:700px) and (max-height:749px) {
  #text1 {
  font-size:18px;
  padding:12px;
  margin-left:3.3em;
  margin-top:16.3em;
  height:7.4em;
  line-height:15px;
}
#rel {
  top:-3.3em;
  line-height:12.7px;
}
#img1 {
  width:58%;
  margin-top:16.2em;
  margin-left:4.8em;
  display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:33.7em;
  display:block;
}
#language {
  left:30em;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:700px) and (max-height:749px) {
  #text1 {
  font-size:18.5px;
  padding:12px;
  margin-left:5em;
  margin-top:17.6em;
  height:7.8em;
  line-height:16px;
}
#language {
  left:33em;
  margin-top:4.2em;
}
#rel {
  top:-2.6em;
  line-height:12.5px;
}
#img1 {
  width:55%;
  margin-top:17.5em;
  margin-left:7.1em;
  display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:750px) and (max-height:799px) {
  #text1 {
  font-size:15px;
  margin-left:3em;
  height:7.8em;
  margin-top:20em;
  line-height:12px;
}
#rel {
  top:-3.9em;
  line-height:12.5px;
}
#img1 {
  width:62%;
  margin-top:16.7em;
  margin-left:4em;
  display:block;
}
#img {
  display:none;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:750px) and (max-height:799px) {
  #text1 {
  font-size:16px;
  margin-left:3.6em;
  margin-top:17.6em;
  height:7.8em;
  line-height:13px;

}
#rel {
  top:-3.2em;
  line-height:12.2px;
}
#img1 {
  width:60%;
  margin-top:15.3em;
  margin-left:4.5em;
  display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:5.3em;
}
}
@media (min-width:400px) and (max-width:449px) and (min-height:750px) and (max-height:799px) {
  #text1 {
  font-size:18px;
  padding:12px;
  margin-left:3.3em;
  margin-top:16.3em;
  height:7.4em;
  line-height:15px;
}
#rel {
  top:-3.3em;
  line-height:12.7px;
}
#img1 {
  width:58%;
  margin-top:16.2em;
  margin-left:4.8em;
  display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:33.7em;
  display:block;
}
#language {
  left:30em;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:750px) and (max-height:799px) {
  #text1 {
  font-size:18.5px;
  padding:12px;
  margin-left:5em;
  margin-top:17.6em;
  height:7.8em;
  line-height:16px;
}
#language {
  left:33em;
  margin-top:4.2em;
}
#rel {
  top:-2.6em;
  line-height:12.5px;
}
#img1 {
  width:55%;
  margin-top:17.5em;
  margin-left:7.1em;
  display:block;
}
#img {
  display:none;
}
#logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:800px) and (max-height:849px) {
  #logo {
    height:60px;
  }
  #rel {
  top:-4em;
  line-height:12.6px;
  left:-2.2em;
  font-size:10px;
}
  #text1 {
    margin-top:23em;
    font-size:16px;
    margin-left:2.4em;
    height:7.1em;
    line-height:12px;
  }
  #img1 {
    margin-top:21em;
    display:block;
    width:65%;
    margin-left:3.7em;
  }
  #img {
    display:none;
  }
  #logo11 {
    left:-29.5em;
  }
  #language {
    left:21.2em;
  }
  
}
@media (min-width:350px) and (max-width:399px) and (min-height:800px) and (max-height:849px) {
  #text1 {
    font-size:15px;
    height:8.2em;
    width:15em;
    margin-top:23em;
    margin-left:3.7em;
    line-height:15px;
  }
  #rel {
  top:-3.9em;
  left:-2em;
  line-height:12.5px;
  font-size:10px;
}
  #img1 {
    margin-top:19em;
    width:58%;
    margin-left:4.6em;
    display:block;
  }
  #language {
    left:24.2em;
  }
  #img {
    display:none;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:800px) and (max-height:849px) {
  #text1 {
    font-size:16px;
    height:8.7em;
    width:14em;
    margin-top:22em;
    margin-left:5.2em;
    line-height:15px;
  }
  #language {
    left:29.2em;
  }
  #rel {
  top:-3em;
  left:-2.1em;
  line-height:11.5px;
  font-size:10px;
}
  #img1 {
    margin-top:20em;
    width:50%;
    margin-left:6.6em;
    display:block;
  }
  #img {
    display:none;
  }
  #logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:800px) and (max-height:849px) {
  #text1 {
    font-size:19px;
    height:7.8em;
    width:14em;
    margin-top:18em;
    margin-left:4.2em;
    line-height:16px;
  }
  #rel {
  top:-2.5em;
  left:-2em;
  line-height:12.5px;
  font-size:10px;
}
  #img1 {
    margin-top:19.4em;
    width:47%;
    margin-left:6.9em;
    display:block;
  }
  #img {
    display:none;
  }
  #language {
    left:33.2em;
    margin-top:4.4em;
  }
  #logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:850px) and (max-height:899px) {
  #logo {
    height:60px;
  }
  #rel {
  top:-4em;
  line-height:12.6px;
  left:-2.2em;
  font-size:10px;
}
  #text1 {
    margin-top:23em;
    font-size:16px;
    margin-left:2.4em;
    height:7.3em;
    line-height:12px;
  }
  #img1 {
    margin-top:21em;
    display:block;
    width:65%;
    margin-left:3.7em;
  }
  #img {
    display:none;
  }
  #logo11 {
    left:-29.5em;
  }
  #language {
    left:21.2em;
  }
  
}
@media (min-width:350px) and (max-width:399px) and (min-height:850px) and (max-height:899px) {
  #text1 {
    font-size:15px;
    height:8.3em;
    width:15em;
    margin-top:23em;
    margin-left:3.7em;
    line-height:15px;
  }
  #rel {
  top:-3.9em;
  left:-2em;
  line-height:12.5px;
  font-size:10px;
}
  #img1 {
    margin-top:19em;
    width:58%;
    margin-left:4.6em;
    display:block;
  }
  #language {
    left:24.2em;
  }
  #img {
    display:none;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:850px) and (max-height:899px) {
  #text1 {
    font-size:18px;
    height:8.2em;
    width:14em;
    margin-top:19.8em;
    margin-left:3.7em;
    line-height:16px;
  }
  #language {
    left:29.2em;
  }
  #rel {
  top:-3em;
  left:-2.1em;
  line-height:11.5px;
  font-size:10px;
}
  #img1 {
    margin-top:19.5em;
    width:60%;
    margin-left:5.4em;
    display:block;
  }
  #img {
    display:none;
  }
  #logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:850px) and (max-height:899px) {
  #text1 {
    font-size:19px;
    height:7.8em;
    width:14em;
    margin-top:18em;
    margin-left:4.2em;
    line-height:16px;
  }
  #rel {
  top:-2.5em;
  left:-2em;
  line-height:12.5px;
  font-size:10px;
}
  #img1 {
    margin-top:18.8em;
    width:53%;
    margin-left:6.5em;
    display:block;
  }
  #img {
    display:none;
  }
  #language {
    left:33.2em;
    margin-top:4.4em;
  }
  #logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:900px) and (max-height:949px) {
  #logo {
    height:60px;
  }
  #rel {
  top:-4em;
  line-height:12.6px;
  left:-2.2em;
  font-size:10px;
}
  #text1 {
    margin-top:23em;
    font-size:16px;
    margin-left:2.4em;
    height:7.3em;
    line-height:12px;
  }
  #img1 {
    margin-top:21em;
    display:block;
    width:65%;
    margin-left:3.7em;
  }
  #img {
    display:none;
  }
  #logo11 {
    left:-29.5em;
  }
  #language {
    left:21.2em;
  }
  

}
@media (min-width:350px) and (max-width:399px) and (min-height:900px) and (max-height:949px) {
#text1 {
    font-size:15px;
    height:8.3em;
    width:15em;
    margin-top:23em;
    margin-left:3.7em;
    line-height:15px;
  }
  #rel {
  top:-3.9em;
  left:-2em;
  line-height:12.5px;
  font-size:10px;
}
  #img1 {
    margin-top:19em;
    width:58%;
    margin-left:4.6em;
    display:block;
  }
  #language {
    left:24.2em;
  }
  #img {
    display:none;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:900px) and (max-height:949px) {
  #text1 {
    font-size:18px;
    height:8.2em;
    width:14em;
    margin-top:19.8em;
    margin-left:3.7em;
    line-height:16px;
  }
  #language {
    left:29.2em;
  }
  #rel {
  top:-3em;
  left:-2.1em;
  line-height:11.5px;
  font-size:10px;
}
  #img1 {
    margin-top:19.5em;
    width:60%;
    margin-left:5.4em;
    display:block;
  }
  #img {
    display:none;
  }
  #logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:450px) and (max-width:499px) and (min-height:900px) and (max-height:949px) {
  #text1 {
    font-size:19px;
    height:7.8em;
    width:14em;
    margin-top:18em;
    margin-left:4.2em;
    line-height:16px;
  }
  #rel {
  top:-2.5em;
  left:-2em;
  line-height:12.5px;
  font-size:10px;
}
  #img1 {
    margin-top:18.8em;
    width:53%;
    margin-left:6.5em;
    display:block;
  }
  #img {
    display:none;
  }
  #language {
    left:33.2em;
    margin-top:4.4em;
  }
  #logo11 {
  margin-left:33.7em;
  display:block;
}
}
@media (min-width:1100px) and (max-width:1199px) {
  #text {
    margin-left:4em;
  margin-top:20.5em;
  line-height:22px;
  }
  #img {
    margin-left:7em;
    margin-top:20em;
  }
  .loader {
  left:35.5em;
  top:5em;  
 
  
}
}
@media (min-width:1200px) and (max-width:1299px) {
  #text {
    margin-left:5em;
  margin-top:20.5em;
  line-height:22px;
  }
  #img {
    margin-left:8.7em;
    width:22%;
    margin-top:19.5em;
  }
  .loader {
  left:41em;
  top:5em;  
 
  
}
}
@media (min-width:1300px) and (max-width:1399px) {
  #text {
    margin-left:2em;
  margin-top:19em;
  line-height:22px;
  }
  #img {
    margin-left:6em;
    width:20%;
    margin-top:20.5em;
  }
  .loader {
  left:41em;
  top:5em;  
 
  
}
}
@media (min-width:1400px) and (max-width:1499px) {
  #text {
    margin-left:4em;
  margin-top:17em;
  }
  #img {
    margin-left:10em;
    margin-top:20em;
  }
  .loader {
  left:41em;
  top:5em;  
 
  
}
}
@media (min-width:1600px) and (max-width:1699px) {
  #logo1 {
    margin-left:39em;
  }
  #img {
    margin-top:22.3em;
    margin-left:7em;
  }
  #text {
    margin-top:16em;
  }
}
@media (min-width:1700px) and (max-width:1799px) {
  #logo1 {
    margin-left:40em;
  }
  .loader {
    left:47em;
  }
}
@media (min-width:1800px) and (max-width:1899px) {
  #logo1 {
    margin-left:43.4em;
  }
  .loader {
    left:47em;
  }
}
@media (min-width:1900px) and (max-width:1999px) {
  #logo1 {
    margin-left:47.4em;
  }
  #img {
    margin-top:20.2em;
    margin-left:7em;
    width:18%;
  }
  #text {
    margin-top:14.5em;
    margin-left:1em;
  }
  .loader {
    left:47em;
  }
}
@media (min-width:2000px) and (max-width:2099px) {
  #logo1 {
    margin-left:48.4em;
  }
  #img {
    width:16%;
    margin-left:15em;
    margin-top:19.5em;
  }  
  .loader {
    left:56em;
  }
  #signa {
    width:8%;
  }  
  #text {
    margin-top:14em;
    margin-left:5em;
  }
}
@media (min-width:2100px) and (max-width:2199px) {
  #logo1 {
    margin-left:51.4em;
  }
  #img {
    width:16%;
  }
  .loader {
    left:56em;
  }
  #signa {
    width:8%;
  }  
  #img {
    margin-top:17em;
    margin-left:24em;
    width:18%;
  }
  #text {
    margin-top:12em;
    margin-left:11em;
  }
}
@media (min-width:2200px) and (max-width:3000px) {
  #logo1 {
    margin-left:60.4em;
  }
  #signa {
    width:8%;
  } 
  #img {
    margin-top:16.5em;
    margin-left:22em;
    width:18%;
  }
  #text {
    margin-top:12em;
    margin-left:11em;
  }
  .loader {
    left:56em;
  }
}
@media (min-width:3020px) and (max-width:3999px) {
  #img {
    width:10%;
  }
  #signa {
    width:4%;
  } 
  #logo1 {
    margin-left:80.4em;
  }
  #language {
    right:1em;
  }
  

}
@media (min-width:4000px) and (max-width:5200px) {
  #img {
    width:6%;
  }
  #language {
    right:1em;
  }
  #signa {
    width:4%;
  } 
  #logo1 {
    margin-left:80.4em;
  }
}
@media (min-width:5201px) and (max-width:6200px) {
  #img {
    width:6%;
  }
  #signa {
    width:4%;
  } 
}


</style>
