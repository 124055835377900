export default {
  "home": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Dr.Stoica este <span style='color:#92D2E2;font-weight:bold'><b> medic </b></span> dermatolog absolvent al generației 92' a Facultății de Medicină din Timișoara.Experiența și dedicația l-au condus către a fi un practician și doctor de <span style='color:#92D2E2;font-weight:bold;'><b>excepție</b></span>.<br>&nbsp;&nbsp;&nbsp;Serviciile sale de tratament variază  de la afecțiuni ale <span style='color:#92D2E2;'><b> pielii </b></span> până la  <span style='color:#92D2E2;font-weight:bold;'><b> chirurgie </b></span> și <span style='color:#92D2E2;font-weight:bold'><b> estetică</b></span>."])},
    "textelefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Dr.Stoica este <span style='color:#183153;font-weight:bold'><b> medic </b></span> dermatolog absolvent al generației 92' a Facultății de Medicină din Timișoara.Experiența și dedicația l-au condus către a fi un practician și doctor de <span style='color:#183153;font-weight:bold;'><b>excepție</b></span>.<br>&nbsp;&nbsp;&nbsp;Serviciile sale de tratament variază  de la tulburări ale <span style='color:#183153;'><b> pielii </b></span> până la  <span style='color:#183153;font-weight:bold;'><b> chirurgie </b></span> și <span style='color:#183153;font-weight:bold'><b> estetică</b></span>."])}
  },
  "skin": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Pentru că știm cât de iritante pot fi <span style='color:#7ADFB5'><b>afecțiunile pielii</b></span> vă oferim  <span style='color:#7ADFB5'><b>consult</b></span> și <span style='color:#7ADFB5'><b>tratament</b></span> pentru acestea."])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Pentru că știm cât de iritante pot fi <span style='color:#183153'><b>afecțiunile pielii</b></span> vă oferim  <span style='color:#183153'><b>consult</b></span> și <span style='color:#183153'><b>tratament</b></span> pentru acestea."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Afișați</b>"])},
    "affection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>1.</span>Acnee"])},
    "affection2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>2.</span>Alopecie sau căderea părului"])},
    "affection3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>3.</span>Carcinomul"])},
    "affection4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>4.</span>Melanom malign"])},
    "affection5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>5.</span>Dermatită atopică"])},
    "affection6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>6.</span>Dermatită seboreică"])},
    "affection7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>7.</span>Infecții"])},
    "affection8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>8.</span>.Lichen plan"])},
    "affection9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>9.</span>Nev - alunițe"])},
    "affection10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>10.</span>.Rosacee"])},
    "affection11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>11.</span>.Herpes genital"])},
    "affection12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>12.</span>Uretrită"])},
    "affection13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>13.</span>Candidoză"])},
    "affection14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>14.</span>Psoriazis"])},
    "affection15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>15.</span>Tulburări de pigmentare"])},
    "affection16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>16.</span>Tumori ale pielii benigne"])},
    "affection17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>17.</span>Ulcer de gambă"])},
    "affection18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>18.</span>Leziuni ale unghilor"])},
    "affection19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>19.</span>Infecția cu virus HPV"])},
    "affection20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>20.</span>Sifilis"])},
    "tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este cel mai frecvent tip de cancer din lume.Acesta se dezvoltă în țesuturile pielii, cât și în ale rinichilor, ficatului, ale sânilor și ale pancreasului."])},
    "tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este un tip de tumoare care apare adesea la nivelul pielii ,dar poate apărea și în alte locuri."])},
    "tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dermatita atopică este o afecțiune care provoacă înroșirea pielii și mâncărimea , fiind mai frecventă la copii."])},
    "tooltip6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este o formă de inflamare cronică care afectează zone ale pielii precum scalpul,sternul și chiar sprâncenele provocând matreață."])},
    "tooltip7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lichenul plan este o boală autoimună a carei apariție se datorează acțiunii de distrucție a limfocitelor."])},
    "tooltip8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosacea este o afecțiune comună a pielii care produce înroșirea anumitor zone ale feței."])},
    "tooltip9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este una dintre cele mai des întalnite boli cu transmitere sexuală a carei simtome trec cel mai adesea neobservate."])},
    "tooltip10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uretrita este o acțiune inflamatorie, de obicei bacteriană a uretrei, foarte frecventă la pacienții mai tineri."])},
    "tooltip11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidoza este o infecție fungică produsă cel mai frecvent de ciuperca Candida Albicans, care poate trăi, alături de alte microorganisme, în tubul digestiv, pe piele si pe mucoase, inclusiv la nivelul florei vaginale."])},
    "tooltip12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psoriazisul este o afecțiune a pielii care cauzeaza mâncarimi si plăci solzoase,apariția fiind frecventă la nivelul genunchilor,coatelor și a scalpului."])},
    "tooltip13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aceasta se mai numește ulcer venos de gambă sau de stază și este o rană care apare la picior, în zona gambei, pe fondul unei afecțiuni a venelor."])},
    "tooltip14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modificările unghiilor apar din cauza anumitor boli, leziuni sau a unei infecţii sau inflamaţii produsă de bacterii sau fungi."])},
    "tooltip15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infecţia cu HPV este o infecţie virală care determină apariţia unor excrescente pe piele sau pe mucoase, excrescente cunoscute in limbaj popular cu denumirea de negi."])},
    "tooltip16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este o infecţie de natură bacteriană cauzată de Treponema Pallidum si se transmite, de cele mai multe ori, prin contact sexual sau prin transfuzii și instrumente medicale nesterilizate."])}
  },
  "surgery": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Noi rămânem în trend cu tehnicile cele mai <span style='color:#183153'><b>noi</b></span> și mai <span style='color:#183153'><b>sigure</b></span> cu care se lucrează in prezent,oferindu-vă cele mai bune tratamente chirurgicale."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Afișați</b>"])},
    "affection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>1</span>.Keratoză actinică"])},
    "affection2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>2</span>.Keratoză seboreică"])},
    "affection3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>3</span>.Xantelasma"])},
    "affection4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>4</span>.Tumori venerice"])},
    "affection5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>5</span>.Veruci vulgare și plane(negi)"])},
    "affection6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>6</span>.Molluscum contagiosum"])},
    "affection7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>7</span>.Chisturi sebacee și alte tumori ale pielii"])},
    "tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keratoza actinică este o leziune la nivelul pielii care se dezvolta după ani de expunere la soare. 5-10% din leziuni se transformă în carcinom cu celule scuamoase fiind un tip de cancer de piele."])},
    "tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keratoza seboreică este o afecțiune comună care determină apariția unor leziuni rotunde sau ovale, care au tendința să crească în dimensiuni."])},
    "tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Xantelasma este o afecțiune care produce apariția unor cucuie galbene inofensive în zona pleoapelor.Xantelasma în funcție de depozitul de colesterol poate fii ușoară,calcaroasă sau semi-solidă."])},
    "tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tumori care se transmit prin contact sexual."])},
    "tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tooltip6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molluscum contagiosum este o infecție virală, cauzată de poxivirusul care îi poarta numele (molluscum contagiosum) și care se manifestă prin apariția unor proeminențe vizibile la nivelul intregului tegument."])},
    "tooltip7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chistul sebaceu este o nodozitate care se dezvoltă încet sub piele."])}
  },
  "aesthetics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru că punem in valoare <span style='color:orange;font-weight:bolder'><b>aspectul</b></span> dumneavoastră vă invităm sa încercați câteva dintre <span style='color:orange'><b> tehnicile noastre estetice</b></span>."])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pentru că punem in valoare <span style='color:#183153;font-weight:bolder'><b>aspectul</b></span> dumneavoastră vă invităm sa încercați câteva dintre <span style='color:#183153'><b> tehnicile noastre estetice</b></span>."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Afișați</b>"])},
    "affection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>1</span>.Lărgirea buzelor"])},
    "affection2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>2</span>.Injecții botox"])},
    "affection3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>3</span>.Injecții cu acid hialuronic"])},
    "affection4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>4</span>.Peeling chimic"])},
    "affection5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>5</span>.Terapia vampir - prp (anti-aging)"])},
    "affection6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>6</span>.Lipoliză chimică"])},
    "affection7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>7</span>.Treads (fire) - silhouette soft"])},
    "lazer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candela Vbeam"])},
    "lazer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosacee și boli vasculare"])},
    "lazer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbium fraxel laser"])},
    "lazer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potenza frecvențe radio"])},
    "lazer5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pico - pentru înlaturarea tatuajelor"])},
    "men1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lărgirea penisului"])},
    "men2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lărgirea obrazului și a bărbiei - masculinizare"])},
    "tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acidul hialuronic și botoxul sunt cele mai uzuale metode de lărgit buzele."])},
    "tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injecțiile botox sau toxina botulinică sunt folosite pentru felurite tratamente estetice."])},
    "tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este folosit pentru a umple riduri și pentru lărgirea buzelor."])},
    "tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peeling-ul chimic este o metodă dermatologică prin care sunt îndepărtate celulele moarte si stimulată apariția altora noi."])},
    "tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O metodă prin care se determină întinerirea pielii prin injectarea unei plasme bogate in plachete(trombocite)."])},
    "tooltip6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Medotă care constă în injectarea unui amestec ce topește depozitele de țesut adipos."])},
    "tooltip7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firele de contur sunt folosite pentru a urca vertical țesuturi faciale care s-au lăsat din cauza vârstei."])},
    "lasertip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cel mai bun in tratarea de roșeață și a capilarelor dilatate de pe față."])},
    "lasertip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosacea este o afecțiune a pielii de durată care se manifestă la nivelul feței."])},
    "lasertip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tratamentul aduce îmbunatațiri la nivelul unor cicatrici ale acneei,înlătura pistrui si imbunătățește tonusul și textura pielii."])},
    "lasertip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Este o tehnică de estetică care folosește unde radio pentru a reduce apariția ridurilor si a pielii lăsate."])},
    "lasertip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Standardul de aur în îndepartarea tatuajelor."])}
  },
  "contact": {
    "day1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luni <b>15:00 - 21:00</b>"])},
    "day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marți <b>15:00 - 21:00</b>"])},
    "day3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miercuri <b>16:00 - 19:00</b>"])},
    "day4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Joi <b>15:00 - 21:00</b>"])},
    "day5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vineri <b>15:00 - 21:00</b>"])},
    "socialmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urmăriți-ne pe rețelele sociale!"])}
  }
}