<template>

<div id="capsule">


</div>
  <nav id="menu">
    <router-link id="de" to="/">&nbsp;Home </router-link> &nbsp;
    <router-link id="re" to="/about">&nbsp;Skin affections</router-link> &nbsp;
    <router-link id="ce" to="/services">&nbsp;Surgery</router-link>&nbsp;
    <router-link id="il" to="/aesthetics">&nbsp;Aesthetics</router-link>&nbsp;
    <router-link id="less" to="/contact">&nbsp;Contact & Schedule </router-link>&nbsp;
  </nav>
  <router-view/>
  <nav id="reel">
  <router-link id="one" to="/"><i class="fa-solid fa-house"></i>&nbsp; </router-link> &nbsp;
    <router-link id="two" to="/about"><i class="fa-solid fa-square-virus"></i>&nbsp;</router-link> &nbsp;
    <router-link id="three" to="/services"><i class="fa-solid fa-star-of-life"></i>&nbsp;</router-link>&nbsp;
    <router-link id="four" to="/aesthetics"><i class="fa-solid fa-pen"></i>&nbsp;</router-link>&nbsp;
    <router-link id="six" to="/contact"><i class="fa-solid fa-clock"></i>&nbsp;</router-link>&nbsp;
</nav>

<div class="locale-changer">
   <text id="lang">Language </text>
    <select id="select" v-model="$i18n.locale">
      <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">
        {{ lang }}
      </option>
    </select>
  </div>
  <button style="background-color:transparent" id="buton22" @click="show = !show;handleClick();" :style="{backgroundColor: active ? '#183153' : 'transparent',color: active ? 'white':'white',}"><i class="fa-solid fa-bars"></i></button>
</template>
<script>
export default {
  name: 'locale-changer',
  data () {
    return { langs: ['ro','en'],
    show:true,
      hover:false }
  },
  methods: {
  handleClick() {
    this.active = !this.active
  }
  }
}

</script>
<style>
#reel {
  display:grid;
  grid-gap:7.1px;
  position:fixed;
  top:4.5em;
  width:100px;
  left:0.5em;
  z-index:99;
}
#buton22 {
  display:none;
  background-color:transparent;
  left:0;
  top:0;
  margin-left:15em;
  margin-top:15.2em;
  position:absolute;
  color:white;
  border:none;
  transition:0.2s ease-in-out;
  padding:5px 12px;
  font-weight:400;
  border-radius:6px;
  font-size:22px;
  font-family: 'Cormorant Garamond', serif;
-moz-osx-font-smoothing: grayscale;

}

.ice {
  display:grid;
  grid-gap:2.1px;
  position:fixed;
  font-size:20px;
  left:3.2em;
  top:3.6em;
  color:white;
  font-weight:lighter;
  z-index:99;
}
#menu {
  position:absolute;
  left:0;
  top:0;
  margin-left:22.5em;
  margin-top:2.4em;
  
}
#de,#re,#ce,#il,#less {
  font-size:24.5px;
}
#one,#two,#three,#four,#five,#six {
  width:1em;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  font-size:18px;
  padding:5px;
  position:relative;
  font-family: 'Cormorant Garamond', serif;
  top:-1.6em;
}
#capsule {
  width:31%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height:0px;
  color: #2c3e50; 
}
#more {
  color:#0DCDC5;
}
h5 {
  position:relative;
  top:-3.2em;
  left:-2em;
  font-size:12px;
  z-index:99;
  text-align:center;
  font-weight:lighter;
  color:white;
}
#p {
  z-index:99 !important;
  font-size:47px;
  position:relative;
  top:-.4em;
  left:-.7em;
  text-align:center;
  font-weight:lighter;
  color:white;
}
h6 {
  position:relative;
  top:-10em;
  font-size:12px;
}
#cub {
  width:7.4%;
  position:absolute;
  margin-left:.1em;  
  top:-1.5em;
  left:-1.35em;
  z-index:99;
}
nav {
  padding:30px;
  position:absolute;
  top:-3.5em;
  font-weight:lighter;
  left:11.8em;
  font-size:22px;
  text-align:center;
  display:block;
}
nav a {
  font-weight: bold;
  z-index:99;
font-size:20px;
  background:white;
  color:grey;
  font-weight:lighter;
  border-radius:1em;
  text-decoration:none;
  padding:2px 14px; 
  top:-2em;
  left:-1em;
  position:relative;
  font-family: 'Cormorant Garamond', serif;
}
nav a:hover {
  background-color:#d0d0d0;
  color:white;
  opacity:0.9;
  transition:0.2s ease-in-out;
}

nav a.router-link-exact-active {
  background:#183153;
  color:white;
  font-weight:lighter;
  border:.5px solid #d0d0d0;
  transition:0.6s ease-in-out;
}
.locale-changer {
  position:absolute;
  top:0;
  right:0;
  margin-right:2.5em;
  margin-top:1.4em;
  width:2em;
  z-index:99;
  
}
#select {
  font-size:16px;
  font-family: 'Cormorant Garamond', serif;
}
#lang {
  position:relative;
  left:-4.8em;
  top:.75em;
  font-size:18px;
  font-weight:900;
  color:#d0d0d0;
  display:none;
  font-family: 'Cormorant Garamond', serif;
}

#logo:hover {
  opacity:0.3;
  cursor:pointer;
}
#rel {
  position:relative;
  top:1.1em;
  
  font-family: 'Cormorant Garamond', serif;
}
@media (min-width:1400px) and (max-width:1499px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:22px;
  }
  #menu {
    margin-left:22em;
    margin-top:1.8em;
  }
  #logo {
    margin-top:0em;
  }
  .locale-changer {
    margin-top:0.77em;
  }
}
@media (min-width:1300px) and (max-width:1399px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:20px;
  }
  .locale-changer {
    margin-top:0.8em;
    margin-right:2.1em;
  }
  #menu {
    margin-top:1.6em;
    margin-left:19.9em;
  }

}
@media (min-width:1200px) and (max-width:1299px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:19px;
    
  }
  #menu {
    margin-left:17.3em;
    margin-top:1.5em;
  }
  .locale-changer {
    right:0;
    top:0;
    margin-top:0.9em;
    margin-right:1.4em;
  }
  #lang {
    font-size:15px;
  }
  #select {
    font-size:12px;
  }
  

}
@media (min-width:1100px) and (max-width:1199px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:16px;
   width:20em; 
   
  }
  #menu {
    width:30em;
    margin-left:14.2em;
    margin-top:1.2em;
  }
  #select {
    font-size:12px;
  }
  #lang {
    font-size:14px;
  }
  .locale-changer {
    margin-top:1em;
  }
}
@media (min-width:1000px) and (max-width:1099px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:13px;
   width:30em; 
   
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
  }
  #rel {
    font-size:14px;
    line-height:8.2px;
    top:2.1em;
  }
  #menu {
    width:30em;
    margin-left:11.8em;
    margin-top:0.7em;
  }
  .locale-changer {
   margin-top:0.85em;
   margin-right:1.5em;
  }
  #select {
    font-size:10px;
  }
  #lang {
    font-size:12px;
  }
}
@media (min-width:900px) and (max-width:999px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:12px;
   width:em; 
  }
  #menu {
    margin-left:13.5em;
    margin-top:0.60em;
  }
  .locale-changer {
margin-top:0.9em;
margin-right:0.6em;
  }
  #lang {
    font-size:10px;
  }
  #select {
    font-size:8px;
  }
  #one,#two,#three,#four,#six {
    top:-5em;
    font-size:11px;
  }
  #rel {
    font-size:13px;
    line-height:7.6px;
    left:-.5em;
    top:-0.37em;
  }
}
@media (min-width:800px) and (max-width:899px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:10px;
  }
  #menu {
    margin-left:10.8em;
    margin-top:0.2em;
  }
  .locale-changer {
margin-top:0.7em;
margin-right:0.8em;
  }
  #lang {
    font-size:10px;
  }
  #select {
    font-size:8px;
  }
  #one,#two,#three,#four,#six {
    font-size:13px;
  }
  #rel {
    font-size:13px;
    line-height:9.2px;
    top:2.37em;
  }
}
@media (min-width:700px) and (max-width:799px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:9px;
  }
  #menu {
    margin-left:8em;
    margin-top:0.1em;
  }
  #rel {
    top:2.5em;
    line-height:5px;
    font-size:12px;
    left:-0.4em;
  }
  #one,#two,#three,#four,#six {
    font-size:7px;
    top:-3em;
  }
  .locale-changer {
    margin-top:0.7em;
margin-right:0.5em;
  }
  #select {
   font-size:6px;
   width:6em;
  }
  #lang {
font-size:9px;
  }
}
@media (min-width:600px) and (max-width:699px) {
  #de, #re, #ce, #il, #will, #less {
    font-size:9px;
    padding:2px;
  }
  #menu {
    margin-top:-0.02em;
    margin-left:8.3em;
  }
  .locale-changer {
   margin-top:0.6em;
   margin-right:0.4em;
  }
  #lang {
    font-size:8px;
  }
  #one,#two,#three,#four,#six {
    font-size:6px;
    padding:3px;
    top:-12.5em;
    grid-gap:2px;
  }
  #rel {
    font-size:8px;
    line-height:7.8px;
    top:-2.8em;
    left:-1em;
  }
  #select {
   font-size:6px;
   margin-top:-0.1em;
  }
  
  
}
@media (min-width:300px) and (max-width:349px) {
#lang 
{
font-size:6px;  
}
#select {
  font-size:4px;
  width:5em;
  
}
.locale-changer {
  margin-right:0em;
  margin-top:1.7em;
}
#one,#two,#three,#four,#six {
    font-size:6px;
    padding:3px;
    top:-14.5em;
    left:-5em;
    grid-gap:2px;
   
  }
  
  #rel {
    font-size:9px;
    top:-3.9em;
    left:-3.8em;
    line-height:5.6px;
  }
  #de, #re, #ce, #il, #will, #less {
   display:none;
  }#menu {
   display:none;
  }
  #reel {
    width:1em;
  }
}
@media (min-width:350px) and (max-width:399px) {
  #lang 
{
font-size:7px;  
}
#select {
  font-size:4px;
  width:5em;
}
.locale-changer {
  margin-right:0em;
  margin-top:2em;
}
#one,#two,#three,#four,#six {
    font-size:6px;
    padding:3px;
    top:-14.5em;
    left:-5em;
    grid-gap:2px;
   
  }
  
  #rel {
    font-size:9px;
    top:-3.9em;
    left:-3.8em;
    line-height:5.6px;
  }
  #de, #re, #ce, #il, #will, #less {
   display:none;
  }#menu {
   display:none;
  }
  #reel {
    width:1em;
  }
}
@media (min-width:400px) and (max-width:449px) {
  #lang 
{
font-size:7px;  
}
#select {
  font-size:4px;
  width:5em;
}
.locale-changer {
  margin-right:0em;
  margin-top:2em;
}
#one,#two,#three,#four,#six {
    font-size:6px;
    padding:3px;
    top:-14.5em;
    left:-5em;
    grid-gap:2px;
   
  }
  
  #rel {
    font-size:9px;
    top:-3.9em;
    left:-3.8em;
    line-height:5.6px;
  }
  #de, #re, #ce, #il, #will, #less {
   display:none;
  }#menu {
   display:none;
  }
  #reel {
    width:1em;
  }
}
@media (min-width:450px) and (max-width:499px) {
  #lang 
{
font-size:7px;  
}
#select {
  font-size:4px;
  width:5em;
}
.locale-changer {
  margin-right:0em;
  margin-top:2.3em;
}
#one,#two,#three,#four,#six {
    font-size:6px;
    padding:3px;
    top:-14.5em;
    left:-5em;
    grid-gap:2px;
   
  }
  
  #rel {
    font-size:9px;
    top:-3.9em;
    left:-3.8em;
    line-height:5.6px;
  }
  #de, #re, #ce, #il, #will, #less {
   display:none;
  }#menu {
   display:none;
  }
  #reel {
    width:1em;
  }
}
@media (min-width:300px) and (max-width:349px) and (min-height:300px) and (max-height:349px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:300px) and (max-height:349px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
  #select {
    margin-left:0em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:300px) and (max-height:349px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0.25em;
  }
}

@media (min-width:450px) and (max-width:499px) and (min-height:300px) and (max-height:349px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0em;
    margin-right:0.3em;
  }
}
@media (min-width:300px) and (max-width:349px) and (min-height:350px) and (max-height:399px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:350px) and (max-height:399px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
  #select {
    margin-left:0em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:350px) and (max-height:399px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0.25em;
  }
}

@media (min-width:450px) and (max-width:499px) and (min-height:350px) and (max-height:399px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0em;
    margin-right:0.3em;
  }
}
@media (min-width:300px) and (max-width:349px) and (min-height:400px) and (max-height:449px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:400px) and (max-height:449px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
  #select {
    margin-left:0em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:400px) and (max-height:449px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0.25em;
  }
}

@media (min-width:450px) and (max-width:499px) and (min-height:400px) and (max-height:449px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0em;
    margin-right:0.3em;
  }
}
@media (min-width:300px) and (max-width:349px) and (min-height:450px) and (max-height:499px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:450px) and (max-height:499px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
  #select {
    margin-left:0em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:450px) and (max-height:499px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0.25em;
  }
}

@media (min-width:450px) and (max-width:499px) and (min-height:450px) and (max-height:499px) {
  #one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-9.4em;
  }
  #select {
    margin-left:1em;
  }
  .locale-changer {
    top:0em;
    margin-right:0.3em;
  }
}

@media (min-width:300px) and (max-width:349px) and (min-height:500px) and (max-height:549px) {
#lang {
  font-size:9px;
}
#select {
  font-size:6px;
  width:5.5em;
  margin-top:-0.1em;
}
.locale-changer {
  margin-right:0.4em;
}
#one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:500px) and (max-height:549px) {
  #lang {
  font-size:9px;
  margin-top:0.1em;
  margin-right:0.1em;
}
#select {
  font-size:6px;
  width:5.5em;
}
.locale-changer {
  margin-right:0.3em;
}
#one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:500px) and (max-height:549px) {
  #one,#two,#three,#four,#six {
    font-size:10px;
    left:-2.8em;
    top:-8.5em;
  }
  #lang {
  font-size:9px;
}
#select {
  font-size:6px;
  width:6em;
  margin-left:-1.2em;
}
.locale-changer {
  margin-right:0.2em;
}
  #rel {
    font-size:10px;
    top:-3.4em;
    left:-3em;
    grid-gap:7px;
    line-height:8px;
  }
  
}
@media (min-width:450px) and (max-width:499px) and (min-height:500px) and (max-height:549px) {
  #one,#two,#three,#four,#six {
    font-size:10px;
    left:-2.8em;
    top:-8.5em;
  }
  #rel {
    font-size:10px;
    top:-3.4em;
    left:-3em;
    grid-gap:7px;
    line-height:8px;
  }
  #lang {
  font-size:9px;
}
#select {
  font-size:6px;
}
.locale-changer {
  margin-right:0.2em;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:550px) and (max-height:599px) {
#lang {
  font-size:9px;
}
#select {
  font-size:6px;
  width:5.5em;
  margin-top:-0.1em;
}
.locale-changer {
  margin-right:0.4em;
}
#one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:550px) and (max-height:599px) {
  #lang {
  font-size:9px;
  margin-top:0.1em;
  margin-right:0.1em;
}
#select {
  font-size:6px;
  width:5.5em;
}
.locale-changer {
  margin-right:0.3em;
}
#one,#two,#three,#four,#six {
    font-size:9px;
    left:-3.5em;
    top:-10.4em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:550px) and (max-height:599px) {
  #one,#two,#three,#four,#six {
    font-size:10px;
    left:-2.8em;
    top:-8.5em;
  }
  #lang {
  font-size:9px;
}
#select {
  font-size:6px;
  width:6em;
  margin-left:-1.2em;
}
.locale-changer {
  margin-right:0.2em;
}
  #rel {
    font-size:10px;
    top:-3.4em;
    left:-3em;
    grid-gap:7px;
    line-height:8px;
  }
  
}
@media (min-width:450px) and (max-width:499px) and (min-height:550px) and (max-height:599px) {
  #one,#two,#three,#four,#six {
    font-size:10px;
    left:-2.8em;
    top:-8.5em;
  }
  #rel {
    font-size:10px;
    top:-3.4em;
    left:-3em;
    grid-gap:7px;
    line-height:8px;
  }
  #lang {
  font-size:9px;
}
#select {
  font-size:6px;
}
.locale-changer {
  margin-right:0.2em;
}
}

@media (min-width:300px) and (max-width:349px) and (min-height:600px) and (max-height:699px) {
  #one,#two,#three,#four,#six {
    font-size:11px;
    left:-2.8em;
    top:-8em;
  }
  #rel {
    font-size:10px;
    top:-3.4em;
    left:-3em;
    grid-gap:7px;
    line-height:8px;
  }
  #lang {
  font-size:9.5px;
}
#select {
  font-size:6.5px;
}
.locale-changer {
  margin-right:0.4em;
}
}
@media (min-width:350px) and (max-width:399px) and (min-height:600px) and (max-height:699px) {
  #one,#two,#three,#four,#six {
    font-size:11px;
    left:-2.8em;
    top:-7.5em;
  }
  #lang {
  font-size:9.5px;
}
#select {
  font-size:6.5px;
}
.locale-changer {
  margin-right:0.4em;
}
  #rel {
    font-size:10px;
    top:-3.4em;
    left:-3em;
    grid-gap:7px;
    line-height:8px;
  }

}
@media (min-width:400px) and (max-width:449px) and (min-height:600px) and (max-height:699px) {
  #lang {
  font-size:9.5px;
}
#select {
  font-size:6.5px;
}
.locale-changer {
  margin-right:0.4em;
}
#one,#two,#three,#four,#six {
    font-size:11px;
    left:-2.6em;
    top:-7.5em;
  }
  #rel {
    font-size:10px;
    top:2em;
    margin-left:1em;
    grid-gap:7px;
    line-height:8px;
  }
}
@media (min-width:450px) and (max-width:499px) and (min-height:600px) and (max-height:699px) {
  #one,#two,#three,#four,#six {
    font-size:11px;
    left:-1.8em;
    top:-6.5em;
  }
  #lang {
  font-size:9.5px;
}
#select {
  font-size:6.5px;
}
.locale-changer {
  margin-right:0.4em;
}
}
@media (min-width:300px) and (max-width:349px) and (min-height:700px) and (max-height:799px) {
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6.5em;
  }
  #rel {
    font-size:10px;
    top:-3.3em;
    left:-2em;
    line-height:11px;
  }
}
@media (min-width:350px) and (max-width:399px) and (min-height:700px) and (max-height:799px) {
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6em;
  }
  #rel {
    font-size:10px;
    top:-3.3em;
    left:-2em;
    line-height:11px;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:700px) and (max-height:799px) {
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6em;
  }
  #rel {
    font-size:10px;
    top:-3.3em;
    left:-2em;
    line-height:11px;
  }
}
@media (min-width:450px) and (max-width:499px) and (min-height:700px) and (max-height:799px) {
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-5.5em;
  }
  #rel {
    font-size:10px;
    top:-3.3em;
    left:-2em;
    line-height:11px;
  }
  #lang {
    font-size:12px;

  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  .locale-changer {
    margin-right:1em;
    margin-top:2.3em;
  }
}
@media (min-width:300px) and (max-width:350px) and (min-height:800px) and (max-height:899px) {
  .locale-changer {
   margin-top:1.5em;
   margin-right:0.2em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6.5em;
  }
  
}
@media (min-width:350px) and (max-width:399px) and (min-height:800px) and (max-height:899px) {
  .locale-changer {
   margin-top:1.8em;
   margin-right:0.7em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6.5em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:800px) and (max-height:899px) {
  .locale-changer {
   margin-top:1.8em;
   margin-right:0.7em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6em;
  }
}
@media (min-width:450px) and (max-width:499px) and (min-height:800px) and (max-height:899px) {
  .locale-changer {
   margin-top:2.3em;
   margin-right:0.7em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #language {
    left:34em;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-5.5em;
  }
}
@media (min-width:300px) and (max-width:350px) and (min-height:900px) and (max-height:949px) {
  .locale-changer {
   margin-top:1.5em;
   margin-right:0.2em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6.5em;
  }
  
}
@media (min-width:350px) and (max-width:399px) and (min-height:900px) and (max-height:949px) {
  .locale-changer {
   margin-top:1.8em;
   margin-right:0.7em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6.5em;
  }
}
@media (min-width:400px) and (max-width:449px) and (min-height:900px) and (max-height:949px) {
  .locale-changer {
   margin-top:1.8em;
   margin-right:0.7em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-6em;
  }
}
@media (min-width:450px) and (max-width:499px) and (min-height:900px) and (max-height:949px) {
  .locale-changer {
   margin-top:2.3em;
   margin-right:0.7em;
  }
  #select {
    font-size:7px;
    margin-top:0.4em;
    margin-left:-1em;
  }
  #lang {
    font-size:12px;
  }
  #language {
    left:34em;
  }
  #one,#two,#three,#four,#six {
    font-size:14px;
    left:-1.8em;
    top:-5.5em;
  }
}

@media (min-width:1700px) and (max-width:1799px) {
  #de,#re,#ce,#il,#less {
  left:3em;

}


}
@media (min-width:1800px) and (max-width:1899px) {
  #de,#re,#ce,#il,#less {
  left:4.5em;

}


}
@media (min-width:1900px) and (max-width:1999px) {
  #de,#re,#ce,#il,#less {
  left:7.5em;

}


}
@media (min-width:2000px) and (max-width:2099px) {
  #de,#re,#ce,#il,#less {
  left:10.5em;

}

}
@media (min-width:2100px) and (max-width:2199px) {
  #de,#re,#ce,#il,#less {
  left:13.5em;

}

}
@media (min-width:2200px) and (max-width:2299px) {
  #de,#re,#ce,#il,#less {
  left:13.5em;

}
}
@media (min-width:2200px) and (max-width:3000px) {
  #de,#re,#ce,#il,#less {
  left:13.5em;

}


}
@media (min-width:3020px) and (max-width:5050px) {
  #de,#re,#ce,#il,#less {
  left:30.5em;

}
.locale-changer {
  right:1em;
}
#select {
  position:absolute;
  height:2em;
}
}
@media (min-width:4000px) and (max-width:5050px) {
  #de,#re,#ce,#il,#less {
  left:60.5em;

}
}
@media (min-width:5200px) and (max-width:6200px) {
  #de,#re,#ce,#il,#less {
  left:80.5em;

}
}

</style>
