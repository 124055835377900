export default {
  "home": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Dr.Stoica is a specialized <span style='color:#92D2E2'><b>dermatologist</b></span> who graduated from the medical school in 1992 in Timisoara.His overall experience and dedication have made him one of the <span style='color:#92D2E2'><b> finest</b></span> practicians.<br>&nbsp;&nbsp;&nbsp;His servicies vary from <span style='color:#92D2E2'> <b>surgery</b> </span> to <span style='color:#92D2E2'> <b>skin</b> </span> related conditions and <span style='color:#92D2E2'><b> aesthetics </b></span>."])},
    "textelefon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Dr.Stoica is a specialized <span style='color:#183153'><b>dermatologist</b></span> who graduated from the medical school in 1992 in Timisoara.His overall prolonged experience and dedication has made him one of the <span style='color:#183153'><b> finest</b></span> doctors in his field.<br>&nbsp;&nbsp;&nbsp;His servicies vary from <span style='color:#183153'> <b>surgery</b> </span> to <span style='color:#183153'> <b>skin</b> </span> related conditions and <span style='color:#183153'><b> aesthetics </b></span>."])}
  },
  "skin": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Because we know how stressful can <span style='color:#86E2BC'><b>skin disorders</b></span> become we offer <span style='color:#86E2BC'><b>cure</b></span> for a long list of skin disease."])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;Because we know how stressful can <span style='color:#183153'><b>skin disorders</b></span> become we offer <span style='color:#183153'><b>cure</b></span> for a long list of skin disease."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<b>Display</b>"])},
    "affection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>1.</span>Acne"])},
    "affection2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>2.</span>Alopecia or hair fall"])},
    "affection3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>3.</span>Carcinomas"])},
    "affection4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>4.</span>Malign Melanomas"])},
    "affection5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>5.</span>Atopic dermatitis"])},
    "affection6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>6.</span>Seborrheic dermatitis"])},
    "affection7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>7.</span>Infections"])},
    "affection8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>8.</span>Lichen Planus"])},
    "affection9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>9.</span>Nevi - moles"])},
    "affection10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>10.</span>Rosacea"])},
    "affection11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>11.</span>Genital herpes"])},
    "affection12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>12.</span>Urethritis"])},
    "affection13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>13.</span>Candidiasis"])},
    "affection14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>14.</span>Psoriasis"])},
    "affection15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>15.</span>Pigmentation disorders"])},
    "affection16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>16.</span>Benign skin tumors"])},
    "affection17": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>17.</span>Leg ulcer"])},
    "affection18": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>18.</span>Fingernail damage"])},
    "affection19": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>19.</span>HPV virus infection"])},
    "affection20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;<span style='color:#B6A27D'>20.</span>Sifilis"])},
    "tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is the most frequent type of cancer.It develops in the skin tissues,kidney tissues,liver and pancreatic ones."])},
    "tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a type of tumour which usually occurs in the skin,altough it can arise in other locations too."])},
    "tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atopic dermatitis is a skin affection that causes rash and the reddening of the skin ,most common found in children."])},
    "tooltip6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is a type of chronic inflamation which affect zones of the skin like the scalp,the sternum and even the eyebrows causing dandruff."])},
    "tooltip7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lichen Planus is an autoimmune disease which aparition is determined by the systematic damage of the white blood cells."])},
    "tooltip8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosacea is a common skin condition that causes blushing or flushing and visible blood vessels in your face."])},
    "tooltip9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is one of the most common STD which symtoms frequently go unnoticed."])},
    "tooltip10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uretritis is an inflamatory action,usually bacterial of the urethra,most commun occurring in younger patients."])},
    "tooltip11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Condidiasis is a fungic infection more frequently caused by Candida Albicans fungus which can live on the digestive tract,skin and even inside the vagina."])},
    "tooltip12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Psoriasis is a skin disease that causes a rash with itchy, scaly patches, most commonly on the knees, elbows, trunk and scalp."])},
    "tooltip13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Also called venous leg ulcer or stasis is a wound that appears on foot,in the calf area,amid an existence of a vein disease."])},
    "tooltip14": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fingernail damage appear because of certain disease,injuries,infections or inflamations produced by bacteria or fungus."])},
    "tooltip15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HPV is a viral infection which determines the aparition of a type of outgrowths on the skin,outgrowths also known as warts."])},
    "tooltip16": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is a bacterial infection caused by Treponema Pallidum and its transmited mostly through sexual contact and unsterilised medical instruments."])}
  },
  "surgery": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp;&nbsp;We like to stay updated to the  <span style='color:#183153'><b> latest </b> </span> surgical tecniques offering you <span style='color:#183153'> <b>the highest and safest </b></span> procedures in surgery matters."])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
    "affection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>1</span>.Actimic Keratosis"])},
    "affection2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>2</span>.Seborrheic keratosis"])},
    "affection3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>3</span>.Xanthelasma"])},
    "affection4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>4</span>.Veneral Tumour"])},
    "affection5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>5</span>.Vulgar warts,plane warts"])},
    "affection6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>6</span>.Molluscum Contagiosum"])},
    "affection7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>7</span>.Sebaceus cysts and other skin tumors"])},
    "tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actimic Keratosis is a type of lesion which develops over years of sun exposure.5-10% of the lesions transform in squamous cells identified as a type of skin cancer."])},
    "tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seborrheic keratosis is a common affection which causes the occurence of a round or oval form lesions,which usually tend to grow in size."])},
    "tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A xanthelasma is a harmless yellow bump on or near your eyelid skin.A xanthelasma can be soft, chalky or semi-solid."])},
    "tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tumors which are transmited through sexual contact."])},
    "tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "tooltip6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Molluscum contagiosum is a viral infection,caused by the same proxy virus (molluscum contagiosum) which manifests through the occurence of visible protrusions."])},
    "tooltip7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The sebaceus cyst is usually maniffest through a nodosity which develops slowly under the skin."])}
  },
  "aesthetics": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp; We understand the necessity for <span style='color:orange'><b>beauty</b></span> therefore we offer a selection of <span style='color:orange'><b> aesthetic interventions</b></span>. "])},
    "text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["&nbsp;&nbsp; We understand the necessity for <span style='color:#183153'><b>beauty</b></span> therefore we offer a selection of <span style='color:#183153'><b> aesthetic interventions</b></span>. "])},
    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Display"])},
    "affection1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>1</span>.Lip augmentation"])},
    "affection2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>2</span>.Botox injections"])},
    "affection3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>3</span>.Hyaluronic acid injections"])},
    "affection4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>4</span>.Chemical peeling"])},
    "affection5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>5</span>.Vampire - prp (anti-aging)"])},
    "affection6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>6</span>.Chemical lipolysis"])},
    "affection7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["<span style='color:#B6A27D'>7</span>.Treads(fire) - silhouette soft"])},
    "lazer1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candela Vbeam"])},
    "lazer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosacea and vascular disease"])},
    "lazer3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erbium fraxel lazer"])},
    "lazer4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potenza radio frequency"])},
    "lazer5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pico - best for tatoo removal"])},
    "men1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Penis enlargement"])},
    "men2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jawline and chin enlargement - masculinization"])},
    "tooltip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hialuronic acid or botox for plumper lips are the most common methods of lip augmentation."])},
    "tooltip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The botox injection or the butulinum neurotoxin is used for various aesthetic treatments."])},
    "tooltip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It is used to fill wrinkles and for lip enlargement."])},
    "tooltip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The chemical peel is a dermatological method used to remove dead skin cells and stimulate the growth of new cells."])},
    "tooltip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A treament used to make the skin look younger by injecting platelet rich plasma."])},
    "tooltip6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A method which consists with the injection of a mixture which melts the residuary adipose tissue."])},
    "tooltip7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fire or contour threads are used to o vertically lift facial tissues that have dropped or become sunken with age."])},
    "lasertip1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The vbeam is the gold standard when it comes to treating redness and dilated capillaries on the face."])},
    "lasertip2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rosacea is a long-term skin condition that typically affects the face."])},
    "lasertip3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The treatment brings significant improvement of acne scars,sun damage, successfully removes superficial wrinkles, increases skin thickness, and improves skin tone and texture."])},
    "lasertip4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Is an aesthetic technique that uses radio frequency in order to reduce the appearance of fine lines and loose skin."])},
    "lasertip5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The gold standard for removing tatoos."])}
  },
  "contact": {
    "day1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Monday <b>15:00 - 21:00</b>"])},
    "day2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuesday <b>15:00 - 21:00</b>"])},
    "day3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wednesday <b>16:00 - 19:00</b>"])},
    "day4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday <b>15:00 - 21:00</b>"])},
    "day5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Friday <b>15:00 - 21:00</b>"])},
    "socialmedia": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow us on social media!"])}
  }
}